import React from 'react'
import { Card, Button } from 'semantic-ui-react'
import { serverurl } from '../constants'

const CustomCard = (props) => {
  const data = props.data
  return (
    <Card
      style={{textAlign: "center"}}
      header={data.header}
      meta={data.subheader}
      color={data.color}
      description={
        <div>
          {data.description}
          <br />
          <br />
          <Button color={data.color} onClick={() => window.location.replace("http://"+serverurl+data.link)}>{data.link}</Button>
        </div>
      }
    />
  )
}

export default CustomCard
