export const style = {
  youtubeTitle: {
    textAlign: 'center',
    fontSize: '20px'
  },
  youtubeBox: {
    boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.2)',
    borderRadius: '8px',
    border: '1px solid #c8c8c8',
    padding: '20px',
    margin: 'auto',
    height: '490px',
    width: '680px',
    marginTop: '5%'
  },
  containerStyle: {
    marginTop: '5%',
    marginBottom: '5%'
  }
}
