import React from 'react'
import { Container, Divider, Card }  from 'semantic-ui-react'
import { CustomCard, CustomHeader, Goldbloom } from './index'
import { style } from '../style.js'
import { data } from '../constants'


class Home extends React.Component {
  render(){
    return (
      <Container style={style.containerStyle}>
        <CustomHeader/>
        <Divider />
        <Card.Group>
          {data.map(e =>  <CustomCard data={e}/> )}
        </Card.Group>
        <Goldbloom/>
      </Container>
    )
  }
}

export default Home
