import React from 'react'
import { Header }  from 'semantic-ui-react'

const CustomHeader = (props) => {
  return (
    <Header as='h1' textAlign='center'>
      <Header.Content> Servilo </Header.Content>
    </Header>
  )
}

export default CustomHeader
