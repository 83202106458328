import React from 'react'
import YouTube from 'react-youtube'
import { youtubeOptions } from '../constants'
import { style } from '../style'
import { Divider } from 'semantic-ui-react'

const Goldbloom = (props) => {
  return (
      <div style={style.youtubeBox}>
        <h1 style={style.youtubeTitle}>Long Live the King</h1>
        <Divider />
        <YouTube
          videoId="GVB57VyIado"
          opts={youtubeOptions}
        />
      </div>
  )
}

export default Goldbloom
