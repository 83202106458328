export const serverurl = "servilo.life"
export const youtubeOptions = {
  height: '390',
  width: '640',
  playerVars: {
    autoplay: 1
  }
}
export const data = [
  {
    "header": "Git",
    "subheader": "Gitlab",
    "description": "Gitlab w/Plex login",
    "link": "/gitlab",
    "color": "green"
  },
  {
    "header": "Cloud",
    "subheader": "Nextcloud",
    "description": "Nextcloud w/Plex login",
    "link": "/nextcloud",
    "color": "blue"
  },
  {
    "header": "Jupyter",
    "subheader": "Jupyter Lab",
    "description": "JupyterHub w/Plex login",
    "link": "/jupyter",
    "color": "orange"
  },
/*  {
    "header": "Search",
    "subheader": "Searx",
    "description": "Privacy-oriented search engine",
    "link": "/searx",
    "color": "green"
  },
*/
/*  {
    "header": "Stream",
    "subheader": "OwnCast",
    "description": "Owncast stream",
    "link": "/owncast",
    "color": "purple"
  },
*/
  {
    "header": "Requests",
    "subheader": "Plex Requests",
    "description": "Request stuff",
    "link": "/requests",
    "color": "yellow"
  },
  {
    "header": "Metrics",
    "subheader": "Plex Metrics",
    "description": "Quis custodiet ipsos custodes?",
    "link": "/metrics",
    "color": "red"
  },
  {
    "header": "Books",
    "subheader": "Calibre library",
    "description": "Libroj",
    "link": "/books",
    "color": "white"
  },
]
